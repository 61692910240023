import api from "services/api";
import serveRequestErrors from "common/serve-request-errors";

const getCitiesListing = async (params) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { lookupID: "CITY", sort: "seq,asc" };
		const res = await api.get.general.list(params);
		const content = res.map(({ city, value, ...o }) => ({ label: city, value: city, ...o }));
		response = { content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getCitiesListing;
