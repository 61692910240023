import pathnames from "routes/pathnames";

import AppLayout from "components/app-layout";
import PageHome from "pages/travel/page-home";
import PageBulk from "pages/travel/page-bulk";
import PageAppStore from "pages/travel/page-app-store";
import PageTripType from "pages/travel/page-trip-type";
import PageQuotation from "pages/travel/page-quotation";
import PageApplicant from "pages/travel/page-applicant";
import PagePayment from "pages/travel/page-declaration-payment";
import PageSummary from "pages/travel/page-summary";
import PageReceipt from "pages/travel/page-receipt";
import { quotationRoutes } from "common/auth-routes";

const routes = [
	{
		path: pathnames.home,
		element: <PageHome />,
	},
	{
		path: pathnames.bulk,
		element: <PageBulk />,
	},
	{
		path: pathnames.appStore,
		element: <PageAppStore />,
	},
	{
		path: pathnames.tripType,
		element: <PageTripType />,
	},
	{
		path: pathnames.quotation,
		element: <PageQuotation />,
		//loader: quotationRoutes,
	},
	{
		path: pathnames.payment,
		element: <PagePayment />,
		//loader: quotationRoutes,
	},
	{
		path: pathnames.applicant,
		element: <PageApplicant />,
		//loader: quotationRoutes,
	},
	{
		path: pathnames.summary,
		element: <PageSummary />,
		//loader: quotationRoutes,
	},
	{
		path: pathnames.receipt,
		element: <PageReceipt />,
	},
];

const enrichedRouters = routes.map(({ ...res }) => {
	return { ...res, element: <AppLayout>{res.element}</AppLayout> };
});

export default enrichedRouters;
