import React, { useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'; // Added for validation

import api from "services/api";
import AppButton from "components/app-button";
import pathnames from "routes/pathnames";

const PageBulk = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Define validation schema
    const validationSchema = Yup.object({
        // Add your validation rules here
        // example:
        // email: Yup.string().email('Invalid email').required('Required'),
    });

    // Initialize formik
    const formik = useFormik({
        initialValues: {
            // Add your initial form values here
            // example:
            // email: '',
            // name: '',
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const payload = {
                    refId: "Test-1234",
					payment_amount: 412345,
					redirect_url: window.location.origin + pathnames.receipt,
                    ...values
                };

                const response = await api.post.travel.payment(payload);

				console.log(response);
				window.location.replace(response?.paymentLink);

                // Handle successful response
                // Example:
                // dispatch(someAction(response.data));
                // navigate('/success');

            } catch (error) {
                // Handle error
                console.error('Submission error:', error);
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <div className="page-bulk">
            <div className="home">
                <main className="main">
                    <div className="main__content main__content--background">
                        <form onSubmit={formik.handleSubmit}>


                            <div className="main__content main__content--form">
                                <AppButton 
                                    type="submit" 
                                    label="Next" 
                                    disabled={formik.isSubmitting || !formik.isValid}
                                />
                            </div>
							</form>
                        </div>
                </main>
            </div>
        </div>
    );
};

export default PageBulk;