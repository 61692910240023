import React, { useMemo, memo, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "common/class-names";

const AppCardInfoBenefitCoverage = (props) => {
	
	const getBenefitList = useMemo(() => {
		const benefitList = props.quotationInfoList?.flatMap((quotation) => quotation.ageGroup.benefits.
			map((benefit) => ({
				benefitName: benefit.benefitName,
				benefitAmount: benefit.coverageAmount,
				ageGroup: quotation.ageGroup.ageGroupName
			}))
		)
		console.log(benefitList)
		return benefitList;
	}, [props.quotationInfoList]);

	const getBenefitAmountByAgeGroupAndName = (ageGroup, benefitName) => {
		const benefit = getBenefitList.find(
		  (item) => (item.ageGroup === ageGroup || item.ageGroup == "Adult & Child") && item.benefitName === benefitName
		);
		return benefit ? benefit.benefitAmount : "N/A";
    };

	const getBenefitNameList = useMemo(() => {  
		const benefitList = props.quotationInfoList?.flatMap((quotation) =>   
			quotation.ageGroup.benefits.map((benefit) => benefit.benefitName)  
		  );  
	 
		const uniqueBenefitList = [...new Set(benefitList)];  
	 
		return uniqueBenefitList;  
    }, [props.quotationInfoList]);

	return (  
        <div className="app-card-info">
            <div className="card-info">
                <p className="card-info__title">Benefit Coverage</p>
                    
                <ul className="list">
                    <li className="list__item">
                        <p className="list__text">Benefits Details</p>
                        <p className="list__text list__text--value">Adult<br/>(18-69 y/o)</p>

                    </li>	

                    {getBenefitNameList?.map((o, index) => (  
                    <li className="list__item" key={index}>  
                        <p className="list__text">{o}</p>  
                        <p className="list__text list__text--value">{getBenefitAmountByAgeGroupAndName(props.ageGroup, o)}</p>  
                    </li>  
                    ))}

                    <p class="list__text">*benefits coverage may vary due to age group</p>
                </ul>
            </div>
        </div>
    );  
};


AppCardInfoBenefitCoverage.propTypes = {
    quotationInfoList: PropTypes.arrayOf().isRequired,
    ageGroup: PropTypes.arrayOf().isRequired
};

export default memo(AppCardInfoBenefitCoverage);
