import React, { memo, useMemo, useState, useCallback, Fragment } from "react";
import { Menu, MenuItem, FormHelperText } from "@mui/material";
import PropTypes from "prop-types";

import classNames from "common/class-names";
import { formatCurrency } from "common/format-currency-pattern";
import AppButton from "components/app-button";
import AppCloseIcon from "components/icons/app-close-icon";
import logo from "assets/images/travel_logo.png";


const AppCard = (props) => {
	const isErrorField = useMemo(() => !!props.error && !!props.touched, [props.error, props.touched]);
	const errorMessage = useMemo(() => (isErrorField ? props.error : ""), [props.error, isErrorField]);
	const [anchorEl, setAnchorEl] = useState(null);
	const [downloadMenuAnchor, setDownloadMenuAnchor] = useState(null);
	const className = useMemo(() => {
		return classNames({
			"main-content__radio": true,
			"main-content__radio--active": props.checked,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.checked]);

	const onHandleChange = () => {
		props.onChange(props.data.planId);
	};

	const onhandleCloseMenu = useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onHandleShowAMenu = useCallback(() => {
		const target = document.querySelector(".main__box");
		setAnchorEl(target);
	}, []);

	const onhandleCloseDownloadMenu = useCallback(() => {
		setDownloadMenuAnchor(null);
	}, [setDownloadMenuAnchor]);

	//prettier-ignore
	const onHandleShowDownloadMenu = useCallback((event) => {
		setDownloadMenuAnchor(event.target);
	},[setDownloadMenuAnchor]);

	// Create dynamic menu items based on documents prop
	const menuItems = useMemo(() => {
		if (!props.documents) return [];
		
		return props.documents.map((doc) => ({
		  label: doc.documentName || doc.documentType,
		  link: doc.filePath,
		  documentDescription: doc.documentDescription,
		}));
	  }, [props.documents]);
	
	const BenefitsTable = ({ planDetail }) => {
		if (!planDetail?.ageGroups) {
			return null;
		}
	
		// Group benefits by category
		const benefitsByCategory = planDetail.ageGroups[0].benefits.reduce((acc, benefit) => {
			if (!acc[benefit.benefitCategory]) {
				acc[benefit.benefitCategory] = [];
			}
			acc[benefit.benefitCategory].push(benefit);
			return acc;
		}, {});
	
	
		return (
			<div className="receipt-form__wrapper--full">
				<div className="benefits-table-wrapper">
					<table className="benefits-table">
						<thead>
							<tr>
								<th>Benefits</th>
								{planDetail.ageGroups.map(group => (
									<th key={group.id}>
										{group.ageGroupName}
										<br />
										{`(${group.minAge} - ${group.maxAge} years)`}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{Object.entries(benefitsByCategory).map(([category, benefits]) => (
								<Fragment key={category}>
									{benefits.map(benefit => (
										<tr key={benefit.id}>
											<td className="benefit-name">
												<div className="benefit-info">
													<img 
														src={benefit.icon} 
														alt={benefit.benefitName} 
														className="benefit-icon"
													/>
													<span className="benefit-info_name">{benefit.benefitName}</span>
												</div>
											</td>
											{planDetail.ageGroups.map(group => {
												const groupBenefit = group.benefits.find(
													b => b.benefitCode === benefit.benefitCode
												);
												return (
													<td key={group.id} className="coverage-amount">
														{groupBenefit 
															? `RM ${formatCurrency(groupBenefit.coverageAmount)}` 
															: '-'}
													</td>
												);
											})}
										</tr>
									))}
								</Fragment>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	};

	return (
		<div className="app-card">
			<div className="main-content">
				<div className="main-content__box">
					<button className={className} type="button" disabled={props.disabled} onClick={onHandleChange} />
				</div>

				<div className="main-content__container">
					<div className="header">
						<div className="header__company">
							<div className="header__image">
								<img src={logo} alt="image" />
							</div>

							<div className="header__content header__content--title">
								<p className="header__title">{props.data?.plan.planName}</p>
								<p className="header__title header__title--content">{props.data?.provider?.providerName}</p>
							</div>

							
						</div>

						<div className="header__company--right">
							<div className="header__content header__content--title">
								<p className="header__title">Premium from</p>
								<p className="header__title header__title--content">{`RM${formatCurrency(props.premiumAmount)}`}</p>
							</div>
						</div>
					</div>
					
					<div className="main-content__divider"></div>

					<div className="header__content">
						<div className="header__wrapper">
							<div className="header__text">Benefit Coverage</div>
						</div>
						<div className="header__wrapper">
							<div className="header__title">{`Up to RM${formatCurrency(props.coverageAmount)}`}</div>
						</div>
					</div>

					<BenefitsTable planDetail={props.data} />

					{/*
					<div className="body">
						<div className="body__content">
							<div className="body__content-row">
								<div className="body__content-item">
									{props?.benefit && props.benefit
										.map((o, i) => {
											return (
													<div className="body__wrapper">
														<img src={o.icon} alt="image" className="body__content-icon" />
														<p className="body__text">{o.benefitName}</p>
														<p className="body__text">Up to RM{formatCurrency(o.coverageAmount)}</p>
													</div>
											);
										})}
								</div>
							</div>
						</div>
					</div>
					*/}
					
					<div className="main-content__divider"></div>

					<div className="footer">
							<AppButton type="button" label="Download Documents" onClick={onHandleShowDownloadMenu}/>
					</div>

					<Menu 
    				    classes={{ root: "download-menu" }} 
    				    anchorEl={downloadMenuAnchor} 
    				    open={!!downloadMenuAnchor} 
    				    onClose={onhandleCloseDownloadMenu}
    				    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    				    transformOrigin={{vertical: 'top', horizontal: 'center'}}
    				  >
    				    {menuItems.length > 0 ? (
    				      menuItems.map((item, index) => (
    				        <MenuItem 
    				          key={`menu-item-${index}`}
    				          title={item.documentDescription}
    				        >
    				          <a 
    				            className="download-menu__text" 
    				            href={item.link}
    				            target="_blank" 
    				            rel="noreferrer"
    				          >
    				            {item.label}
    				          </a>
    				        </MenuItem>
    				      ))
    				    ) : (
    				      <MenuItem disabled className="no-documents-menu-item">
    				        <span className="download-menu__text download-menu__text--no-documents">
    				          There are no documents available
    				        </span>
    				      </MenuItem>
    				    )}
    				  </Menu>
				</div>
			</div>

			<FormHelperText className="main-content__error">{errorMessage}</FormHelperText>
		</div>
	);
};

AppCard.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		plan: PropTypes.shape({
			id: PropTypes.string.isRequired,
			providerId: PropTypes.string.isRequired,
			planName: PropTypes.string,
			planCode: PropTypes.string,
			description: PropTypes.string,
			planStatus: PropTypes.string,
			provider: PropTypes.string
		}),
		providerId: PropTypes.string,
		provider: PropTypes.shape({
			id: PropTypes.string.isRequired,
			providerName: PropTypes.string,
			providerCode: PropTypes.string,
			contactPerson: PropTypes.string,
			contactEmail: PropTypes.string,
			contactPhone: PropTypes.string,
			providerStatus: PropTypes.string
		}),
		providerAgeGroupId: PropTypes.string,
		ageGroup: {
            id: 1,
            ageGroupName: PropTypes.string,
            minAge: PropTypes.string,
            maxAge: PropTypes.string,
            ageGroupType: PropTypes.string,
            ageGroupStatus: PropTypes.string
        },
		subLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}),
	benefit: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			benefitName: PropTypes.string,
			benefitCode: PropTypes.string,
			benefitCategory: PropTypes.string,
			description: PropTypes.string,
			icon: PropTypes.string,
			seq: PropTypes.string,
			benefitStatus: PropTypes.string,
		})
	),
	documents: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			travelPlanId: PropTypes.string,
			documentType: PropTypes.string,
			documentName: PropTypes.string,
			documentDescription: PropTypes.string,
			filePath: PropTypes.string,
			status: PropTypes.string,
		})
	),
	coverageAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	premiumAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	checked: PropTypes.bool,
	touched: PropTypes.bool,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

export default memo(AppCard);
