import React, { useMemo, useCallback, useRef, useState, Fragment } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, FieldArray, FormikProvider, setNestedObjectValues } from "formik";

import COMMON from "common";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import classNames from "common/class-names";
import { CALENDAR_FORMAT } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { getDateOfBirthAge } from "common/get-date-of-birth-age";
import formatPassportString from "common/format-passport-string";
import { getDateOfBirthNRIC, validateNRIC } from "common/nric-helpers";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppCardInfo from "components/app-card-info";
import AppButtonInput from "components/app-button-input";
import AppSearchInput from "components/app-search-input";
import AppSelectInput from "components/app-select-input";
import AppMobileInput from "components/app-mobile-input";
import AppCloseIcon from "components/icons/app-close-icon";
import AppMaskingInput from "components/app-masking-input";
import AppCalendarInput from "components/app-calendar-input";
import AppCard from "components/pages/page-quotation/app-card";
import CardTripValue from 'components/pages/page-quotation/app-trip-details-card';
import AppChevronIcon from "components/icons/app-chevron-icon";
import AppMultiSelectInput from "components/app-multi-select-input";
import AppCardContainer from "components/pages/page-quotation/app-card-container";
import AppRegenerateModal from "components/pages/page-quotation/app-regenerate-modal";
import AppAccordionContainer from "components/pages/page-quotation/app-accordion-container";
import api from "services/api";
import getOccupationListing from "services/get-occupation-listing";
import getRelationshipListing from "services/get-relationship-listing";
import getMaritalStatusListing from "services/get-maritalStatus-listing";
import getIdentificationListing from "services/get-identification-listing";
import { setQuotation } from "store/slices/travel";
import addIcon from "assets/images/add-green-icon.svg";
import refreshIcon from "assets/images/refresh-icon.svg";
import editIcon from "assets/images/pages/page-quotation/edit-icon.svg";
import clockIcon from "assets/images/pages/page-quotation/clock-icon.webp";
import emptyIcon from "assets/images/pages/page-quotation/empty-box-icon.webp";
import logo from "assets/images/travel_logo.png";
import WarningTextBox from "components/pages/app-warning-text-box";

const calendarDisplayFormat = CALENDAR_FORMAT.DATE_FORMAT + " " + CALENDAR_FORMAT.MONTH_FORMAT + " " + CALENDAR_FORMAT.YEAR_FORMAT;

const PagePayment = () => {

	//prettier-ignore
	const initialValues = useMemo(() => {
		let payload = {
		}

		return payload;
	}, []);

	//prettier-ignore
	const formik = useFormik({
		validateOnBlur: true,
		initialValues
	});

	const onHandleCheckbox = useCallback((event) => {
		const isChecked = event.target.checked; 
		const name = event.target.name;        
		console.log(`Checkbox '${name}' is now: ${isChecked}`);
	}, []);
	
	return (
		<div className="page-payment">
			<div className="payment">
				<form className="payment__form" onSubmit={formik.handleSubmit}>
					<p className="payment__title">Bank Details</p>
							<AppCardContainer divider={false} description="Please add your bank account details for future payments/refunds/surrender of policy.">
									<div className="payment__box">
											<div className="payment__wrapper">
												<div><WarningTextBox text="Please ensure the bank account details are the same as the insured's for a smooth claim payout."></WarningTextBox></div>

												<div className="payment__box-body">
													{/* prettier-ignore */}
													<AppSelectInput required type="text" name="bankName" label="Bank Name" placeholder="Please Select" loadOptions={getMaritalStatusListing} value={formik.values.maritalStatus} error={formik.errors.maritalStatus} touched={formik.touched.maritalStatus} onChange={formik.handleChange} disabled={formik.isSubmitting} />

													{/* prettier-ignore */}
													<AppSelectInput required type="text" name="accountHolderName" label="Account Holder Name" placeholder="e.g. Lee Kah Chun" loadOptions={getMaritalStatusListing} value={formik.values.maritalStatus} error={formik.errors.maritalStatus} touched={formik.touched.maritalStatus} onChange={formik.handleChange} disabled={formik.isSubmitting} />
												
													{/* prettier-ignore */}
													<AppInput required type="text" maxLength={100} name="bankAccountNumber" label="Bank Account No" placeholder="e.g. 1234567890" value={formik.values.name} touched={formik.touched.name} error={formik.errors.name} onChange={formik.handleChange} disabled={formik.isSubmitting} />
												
													<p className="payment__box-body-text"></p>

													<p className="payment__box-body-text">Bank Account Details
														<p className="payment__box-body-text-description">You are requaired to carefully read and tick the following declarations to complete your purchase</p>
														<AppCheckbox 
															onClick={onHandleCheckbox}
															label="I confirm that I have read and agree to the Product Disclosure Sheet, Policy Contract, important Notice, and Declaration"
														/>
													</p>

													<p p className="payment__box-body-text">Marketing Consent
														<p className="payment__box-body-text-description"></p>
														<AppCheckbox 
															onClick={onHandleCheckbox}
															label="I expressly agree to receive marketing communication from Etiqa General Insurance Berhad or Maybank Group, Etiqa General InsuranceBerhad’s agents or strategic partners and other third parties ('other entities') as Etiqa General Insurance Berhad deems fit about products and services that may be of interest to me."
														/>
													</p>
												</div>
											</div>
									</div>
							</AppCardContainer>

							<div className="payment__button-container">
								<AppButton outline type="button" label="Back" />
								<AppButton type="button" label="Continue" />
							</div>
				</form>
			</div>
		</div>
	);
};

export default PagePayment;
