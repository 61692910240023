import React, { useMemo, useCallback, useState, Fragment, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { compose } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

import COMMON from "common";
import classNames from "common/class-names";
import { formatDatePattern } from "common/calendar";
import serveRequestErrors from "common/serve-request-errors";
import { formatCurrency } from "common/format-currency-pattern";
import AppButton from "components/app-button";
import AppCardInfo from "components/app-card-info";
import AppCardAccordion from "components/pages/page-summary/app-card-accordion";
import withAppTimeout from "contexts/with-app-timeout";
import api from "services/api";
import pathnames from "routes/pathnames";
import AppCheckbox from "components/app-checkbox";
import AppCardInfoBenefitCoverage from "components/app-card-info-benefit-coverage"

const PageSummary = (props) => {
	const navigate = useNavigate();
	const travel = useSelector((state) => state.travel);
	const applicantInfo = useMemo(() => travel?.applicant, [travel?.applicant]);
	const quotationInfo = useMemo(() => travel?.quotation, [travel?.quotation]);
	const quotationInfoList = useMemo(() => travel?.quotationList, [travel?.quotationList]);
	const referralCode = useMemo(() => travel?.referralCode || "", [travel?.referralCode]);
	const tripDetails = useMemo(() => travel?.travelDetails, [travel?.travelDetails]);
	const summaryDetails = useMemo(() => travel?.summaryDetails, [travel?.summaryDetails]);
	const travelerInfo = "";
	const coveredMemberInfo = "";
	const selectedQuotation = "";
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [buttonStates, setButtonStates] = useState([  
		{ id: 0, isChecked: false },  
		{ id: 1, isChecked: false },  
	]);  

	const formik = useFormik({
		initialValues: { referralCode: referralCode },
	});

	const onHandleNavigateReceipt = () => {
		navigate(pathnames.receipt);
	};

	const onHandleNavigateApplicant = () => {
		sessionStorage.setItem('fromSum', 'true');
		navigate(pathnames.applicant);
	}

	const onHandleSubmitPayment = async () => {
		let confirmResponse = null;

		try {
			if(checkIfAllButtonsChecked)
			{
				let paymentPayload = {
					TripDetails: tripDetails,
					planId: quotationInfo,
					summary: tripDetails.coverageType === "GROUP" ? 
                    summaryDetails.data : 
                    summaryDetails,
					referralCode: {referralCode : referralCode},
					redirectURL: window.location.origin + pathnames.receipt,
				};

				console.log("paymen payload:",paymentPayload);

				confirmResponse = await api.post.travel.payment(paymentPayload);

				//console.log("Confirm response: ", confirmResponse);

				if(confirmResponse)
				{
					window.location.replace(confirmResponse?.paymentLink);
				}
			}
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmitting(false);
		}

	};

	const onHandleSubmitConfirm = async () => {
		let response = null;

		//onHandleNavigateReceipt();
		setIsSubmitting(true);
		try {
			let payload = {
				
				coveredMembers: Array(coveredMemberInfo?.persons > 1 ? coveredMemberInfo.persons - 1 : 0)
				.fill(null)
				.map(() => ({
					name: "",
					identificationType: "NRIC",
					identificationNo: "",
					nationality: "",
					gender: "",
					dob: "",
					relationship: "",
					mobile: "",
					prefixNo: "+60",
					email: "",
				}))
			};

			response = await api.post.motor.confirm(payload);
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			setIsSubmitting(false);
		}

		if (response) {
			onHandleSubmitPayment();
		}
	};

	const onHandleCheckBox = (id) => {
		setButtonStates((prevState) =>  
			prevState.map((button) =>  
				button.id === id  
					? { ...button, isChecked: !button.isChecked }  
						: button  
			)  
		);  
	}

	

	const getButtonStateById = (id) => {  
		return buttonStates.find((button) => button.id === id).isChecked;  
	};  

	const checkIfAllButtonsChecked = useMemo(() => {  
		return buttonStates.every((button) => button.isChecked);  
	}, [buttonStates]);  

	const formatDateWithHyphens = (date) => {
		return date ? new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-') : "-";
	};

	const coverageMap = {
		'INDIVIDUAL': "INDIVIDUAL",
		'INDIVIDUAL_V_SPOUSE': "INDIVIDUAL WITH SPOUSE",
		'FAMILY': "FAMILY",
		'GROUP': "GROUP",
	};

	//prettier-ignore
	const TripData = useMemo(() => {
	    const tripTypeMap = {
	        'ONE_WAY': "ONE WAY TRIP",
	        'TWO_WAY': "TWO WAY TRIP",
	        'ANNUAL_TRIP': "ANNUAL TRIP",
	    };

	    // Base info array with common fields
	    const baseInfo = [
	        { label: "Travel Direction", value: tripDetails?.travelDirection ?? "-" },
	        { label: "Destination", value: tripDetails?.destination ?? "-" },
	        { label: "Coverage Type", value: coverageMap[tripDetails?.coverageType] ?? "-" },
	        { label: "Number of Persons", value: tripDetails?.persons ?? "-" },
	    ];

	    // Date-related fields based on travel direction
	    const dateInfo = tripDetails?.travelDirection === COMMON.TRAVEL_DIRECTION.INBOUND
	        ? [
	            { label: "Arrival Date", value: formatDateWithHyphens(tripDetails?.arrival) ?? "-" },
	            { label: "Return Date", value: formatDateWithHyphens(tripDetails?.return) ?? "-" },
	        ]
	        : [
				{ label: "Trip Type", value: tripTypeMap[tripDetails?.tripType] ?? "-" },
	            { label: "Departure Date", value: formatDateWithHyphens(tripDetails?.depart) ?? "-" },
	            { label: "Return Date", value: formatDateWithHyphens(tripDetails?.return) ?? "-" },
	        ];

	    return {
	        title: "Trip Details",
	        info: [
	            ...baseInfo.slice(0, 1), // Travel Direction
	            ...dateInfo,             // Date fields based on direction
	            ...baseInfo.slice(1)     // Remaining fields
	        ]
	    };
	}, [tripDetails]);

	//prettier-ignore
	const PlanData = useMemo(() => {
		const defaultData = {
			title: "Plan Details",
			info: [
				{ label: "Provider Name", value: "-" },
				{ label: "Plan Name", value: "-" },
				{ label: "Plan Description", value: "-" }
			]
		};
	
		// Return default data if quotationInfoList is empty or undefined
		if (!quotationInfoList || quotationInfoList.length === 0) {
			return defaultData;
		}
	
		return {
			title: "Plan Details",
			info: [
				{ 
					label: "Provider Name", 
					value: quotationInfoList[0]?.provider?.providerName || "-"
				},
				{ 
					label: "Plan Name", 
					value: quotationInfoList[0]?.plan?.planName || "-"
				},
				{ 
					label: "Plan Description", 
					value: quotationInfoList[0]?.plan?.description || "-"
				}
			],
		};
	}, [quotationInfoList]);

	const DeclarationData = useMemo(() => ({
		info: [
			{ label: "Declaration 1", value: (
				<span className="summary__text">
					I confirm that the information provided is true and accurate to the best of my knowledge.<span className="summary__tnc-required">*</span>
				</span>
			)},
			{ label: "Declaration 2" , value: (
				<span className="summary__text">
					I confirm that I have read and understood the Product Disclosure Sheet and Policy Wording from the chosen provider and agtree to be bound by the terms and conditions of the policy contract.<span className="summary__tnc-required">*</span>
				</span>
			)},
		],
		
	}), []);

	const totalQuotationData = useMemo(() => {  
		// Early return if no summary details  
		if (!summaryDetails) return null;  
		if (!tripDetails) return null;  
	  
		// Helper function to get age group data  
		const getAgeGroupData = (data, groupName) => {  
		  const group = data?.ageGroupPax?.find(g => g.ageGroupName === groupName) || {};  
		  return {  
			count: group.clientCount || 0,  
			inPremium: group.inPremium || 0,  
			groupPremium: group.groupPremium || 0,  
			minAge: group.minAge,  
			maxAge: group.maxAge  
		  };  
		};  
	  
		// Get data based on coverage type  
		const data = tripDetails?.coverageType === "GROUP" ? summaryDetails?.data : summaryDetails;  
		const totalPremium = tripDetails?.coverageType === "GROUP" ?   
		  data?.totalPremium : summaryDetails?.totalPremium;   
	  
		// Generate age group sections dynamically from API data  
		const ageGroupSections = data?.ageGroupPax?.reduce((acc, group) => {  
		  const groupKey = group.ageGroupName.toLowerCase().replace(/\s+/g, '');  
		  const ageRange = group.maxAge ?   
			`(${group.minAge} - ${group.maxAge} years old)` :   
			`(${group.minAge}+ years old)`;  
	  
			if(tripDetails?.coverageType === 'GROUP')
			{
				return {  
					...acc,  
					[`${groupKey}AgeGroup`]: [{  
					  label: `${group.ageGroupName} ${ageRange}`,  
					  value: `${group.clientCount} x RM${formatCurrency(group.groupPremium)} = RM${formatCurrency(group.total)}`  
					}]  
				  };  
			}else
			{
				return {  
					...acc,  
					[`${groupKey}AgeGroup`]: [{  
					  label: `${group.ageGroupName} ${ageRange}`,  
					  value: `${group.clientCount} x RM${formatCurrency(group.inPremium)} = RM${formatCurrency(group.total)}`  
					}]  
				  };  
			}
		}, {});  
	  
		// Return combined data  
		return {  
		  premium: [  
			{ label: "Premium Amount", value: `` }  
		  ],  
		  ...ageGroupSections,  
		  grossPremium: [  
			{   
			  label: "Gross Premium",   
			  value: `RM${formatCurrency(totalPremium)}`,   
			  bold: true,   
			  divider: true   
			},  
			{   
			  label: "Stamp Duty",   
			  value: `Included`   
			},  
			{   
			  label: "SST (8%)",   
			  value: `Included`   
			}  
		  ],  
		  totalPayable: [  
			{   
			  label: "Total Payable:",   
			  value: `RM${formatCurrency(totalPremium)}`,   
			  divider: true,   
			  bold: true   
			}  
		  ],
		  commissionInfo: [
            { label: `25% commission fee amounting to RM${formatCurrency(totalPremium * 0.25)} is payable to VKA Wealth Planners.` }
        	]
		};  
	  }, [summaryDetails, tripDetails]);  
	  
	  // Usage example:  
	  const SummarySection = () => {  
		return (  
		  <div>  
			{/* Premium sections */}  
			{Object.entries(totalQuotationData).map(([sectionKey, items]) => (  
			  <div key={sectionKey}>  
				{items.map((item, index) => (  
				  <div key={index} className={`summary-row ${item.divider ? 'divider' : ''}`}>  
					<span className={item.bold ? 'bold' : ''}>{item.label}</span>  
					<span className={item.bold ? 'bold' : ''}>{item.value}</span>  
				  </div>  
				))}  
			  </div>  
			))}  
		  </div>  
		);  
	  };  

	const className = useCallback((obj) => {
		if(!obj) return null;
		return classNames({
			"quotation-list__text": true,
			"quotation-list__description": obj?.bold,
		});
	}, []);

	const valueClassName = useCallback((obj) => {
		if(!obj) return null;
		return classNames({
			"quotation-list__text": !obj?.bold,
			"quotation-list__text--value": !obj?.bold,
			"quotation-list__description": obj?.bold,
			"quotation-list__description--value": obj?.bold,
		});
	}, []);

	const isPassport = (idType) =>{
		return idType === COMMON.IDENTIFICATION_TYPE.PASSPORT;
	}

	useEffect(() => {
		// Check if the session flag is set
		const fromApp = sessionStorage.getItem('fromApp');
		const fromSum = sessionStorage.getItem('fromSum');
	
		if (!fromApp && !fromSum) {
		  // If no session flag, redirect to home page
		  navigate(pathnames.home, { replace: true });
		}
	
		// Add event listener to handle page refresh
		const handleBeforeUnload = () => {
		  sessionStorage.removeItem('fromApp');
		  sessionStorage.removeItem('fromSum');
		};
	
		window.addEventListener('beforeunload', handleBeforeUnload);
	
		// Cleanup the event listener on component unmount
		return () => {
		  window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [navigate]);

	return (
		<div className="page-summary">
			<div className="summary">
				<div className="summary__form">
					<div className="summary__header">
						<p className="summary__title">Quotation Summary</p>
						{/*prettier-ignore*/}
						<p>Your quotation is about to expire in <b>{props.timer}</b></p>
					</div>

					<div className="summary__container">
						<div className="summary__wrapper">
							<AppCardInfo data={TripData} />

							<AppCardInfo data={PlanData} />
							<AppCardInfoBenefitCoverage quotationInfoList={quotationInfoList} ageGroup="Adult" />

						</div>

						<div className="summary__wrapper">
							<div className="total-quotation">
								<div className="total-quotation__container">
									<div className="total-quotation__wrapper">
										<p className="total-quotation__title">Total Quotation</p>
									</div>

									<div className="total-quotation__wrapper">
										<div className="total-quotation__form">
											{totalQuotationData && Object.keys(totalQuotationData).map((obj, index) => {
												const quotation = totalQuotationData[obj];
												const notLastItem = "";//index + 1 !== Object.keys(totalQuotationData).length;
												const noBenefit = obj === "benefits"; //&& selectedQuotation?.selectedExtraBenefit.length === 0;
												const isCommissionInfo = obj === "commissionInfo";
												
												return (
													<Fragment key={index}>
														{!noBenefit &&
															quotation?.map((o) => {
																if (isCommissionInfo) return <div className="total-quotation__info-text">{o?.label}</div>;

																return (
																	<ul className="quotation-list" key={o?.label}>
																		{o?.divider == true && <p className="quotation-list__border"> </p>}
																		<li className="quotation-list__item">
																			<p className={className({ bold: o?.bold })}>{o?.label}</p>
																			<p className={valueClassName({ bold: o?.bold, value: true })}>{o?.value}</p>
																		</li>
																	</ul>
																);
															})}

														{notLastItem && !noBenefit && <div className="total-quotation__divider" />}
													</Fragment>
												);
											})}
										</div>
									</div>
								</div>
							</div>

							{tripDetails?.coverageType !== "GROUP" &&
							<div className="total-quotation">
								<div className="total-quotation__container">				
									<div className="total-quotation__wrapper">
										<p className="total-quotation__title">Applicants Details</p>
									</div>

								<div className="total-quotation__wrapper">
									<div className="total-quotation__form">
										<ul className="quotation-list" key="label">
											<p className="quotation-list__border"> </p>
											<li className="quotation-list__item">
												<p className={className({ bold: false})}>Name</p>
												<p className={valueClassName({ bold: false, value: true })}>{applicantInfo?.applicantInformation.name}</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false})}>Email</p>
												<p className={valueClassName({ bold: false, value: true })}>{applicantInfo?.applicantInformation.email}</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false})}>Identification Type</p>
												<p className={valueClassName({ bold: false, value: true })}>{applicantInfo?.applicantInformation.identificationType}</p>
											</li>
											{!isPassport(applicantInfo?.applicantInformation.identificationType) ?
												<li className="quotation-list__item">
													<p className={className({ bold: false})}>Identification Number</p>
													<p className={valueClassName({ bold: false, value: true })}>{applicantInfo?.applicantInformation.nric}</p>
												</li>
											: 
												<>
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Passport</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{applicantInfo?.applicantInformation.passport}
														</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Passport Issued Date</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{formatDateWithHyphens(applicantInfo?.applicantInformation.passportIssuedDate)}
														</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Passport Expired Date</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{formatDateWithHyphens(applicantInfo?.applicantInformation.passportExpiredDate)}
														</p>
													</li>
												</>
											}
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>Age</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.age + " years old"}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>Birth Date</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{formatDateWithHyphens(applicantInfo?.applicantInformation.birthDate)}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>Gender</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.gender}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>Address</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.address1}
												{applicantInfo?.applicantInformation.address2 && ", " + applicantInfo?.applicantInformation.address2}
												{applicantInfo?.applicantInformation.address3 && ", " + applicantInfo?.applicantInformation.address3}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>City</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.city}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>State</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.state}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>Postcode</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.postcode}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>Mobile</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.mobileNoFull}
												</p>
											</li>
											<li className="quotation-list__item">
												<p className={className({ bold: false })}>Nationality</p>
												<p className={valueClassName({ bold: false, value: true })}>
												{applicantInfo?.applicantInformation.nationality}
												</p>
											</li>
										</ul>
									</div>
									<div className="total-quotation__form">
										<ul className="quotation-list" key="label">
											<p className="quotation-list__border"> </p>
		
											{applicantInfo?.coveredMembers.map((member, index) => (  
												<p>
													<li className="quotation-list__item">
														<p className={className({ bold: true})}>Covered Member {index + 1}</p>
													</li>
															
													<li className="quotation-list__item">
														<p className={className({ bold: false})}>Name</p>
														<p className={valueClassName({ bold: false, value: true })}>{member.name}</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false})}>Email</p>
														<p className={valueClassName({ bold: false, value: true })}>{member.email}</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false})}>Identification Type</p>
														<p className={valueClassName({ bold: false, value: true })}>{member.identificationType}</p>
													</li>
													{!isPassport(member.identificationType) ?
														<li className="quotation-list__item">
															<p className={className({ bold: false})}>Identification Number</p>
															<p className={valueClassName({ bold: false, value: true })}>{member.nric}</p>
														</li>
													: 
														<>
															<li className="quotation-list__item">
																<p className={className({ bold: false })}>Passport</p>
																<p className={valueClassName({ bold: false, value: true })}>
																{member.passport}
																</p>
															</li>
															<li className="quotation-list__item">
																<p className={className({ bold: false })}>Passport Issued Date</p>
																<p className={valueClassName({ bold: false, value: true })}>
																{formatDateWithHyphens(member.passportIssuedDate)}
																</p>
															</li>
															<li className="quotation-list__item">
																<p className={className({ bold: false })}>Passport Expired Date</p>
																<p className={valueClassName({ bold: false, value: true })}>
																{formatDateWithHyphens(member.passportExpiredDate)}
																</p>
															</li>
														</>
													}
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Age</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{member.age + " years old"}
														</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Birth Date</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{formatDateWithHyphens(member.birthDate)}
														</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Gender</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{member.gender}
														</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Mobile</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{member.mobileNoFull}
														</p>
													</li>
													<li className="quotation-list__item">
														<p className={className({ bold: false })}>Nationality</p>
														<p className={valueClassName({ bold: false, value: true })}>
														{member.nationality}
														</p>
													</li>
												</p>
											))}  
							
										</ul>
							
										
									</div>
								</div>
							</div>
						</div>
}

							{DeclarationData.info.map((o, index) => {
								return (
								<li className="quotation-list__item">
									<AppCheckbox 
										onClick={() => onHandleCheckBox(index)}
										label={o.value}
										value={getButtonStateById(index)}
									/>
								</li>
							)})}
						
							<div className="summary__button-container">
								<AppButton outline type="button" label="Back" onClick={onHandleNavigateApplicant} disabled={isSubmitting} />
								<AppButton type="button" label="Continue" onClick={onHandleSubmitPayment} disabled={isSubmitting && !checkIfAllButtonsChecked} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default compose(withAppTimeout)(PageSummary);
